import React from "react";
import ErrorBoundary from '../components/ErrorBoundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {ThemeProvider} from 'styled-components';
import theme from '../components/theme';
import { Router } from "@reach/router";
import Donate from "../components/donate";

function DonatePage() {
  return (
    <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Helmet>
          </Helmet>
          <Router basepath="/donate">
            <Donate path="/:userId" />
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </HelmetProvider>
  );
}

const helmetContext = {}

export default DonatePage;
