import * as Sentry from '@sentry/browser'

const isLocal = process.env.NODE_ENV === 'development'

export function initSentry() {
  if (isLocal) {
    return
  }

  Sentry.init({
    dsn: 'https://c47d3021ef1e44eebd5e661cc575f94f@o463307.ingest.sentry.io/5633837',
    environment: process.env.NODE_ENV === 'production' && process.env.REACT_APP_STAGE ? process.env.REACT_APP_STAGE : process.env.NODE_ENV,
  })
}

export function logError(error, errorInfo = null) {
  if (isLocal) {
    return
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo)
    Sentry.captureException(error)
  })
}

export function onError(error) {
  let errorInfo = {}
  let message = error.toString()

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error
    message = error.message
    error = new Error(message)
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url
  }

  logError(error, errorInfo)

  alert(message)
}
