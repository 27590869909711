import { theme } from '@primer/components'
import deepmerge from 'deepmerge'

import {/*colors: colorPrimitives,*/ typography} from '@primer/primitives'
import {lighten, rgba, desaturate} from 'polished'

const colorPrimitives = {
  'black': '#1b1f23',
  'white': '#fff',
  'gray': ["#f7fafc", '#e3e8ee', '#c1c9d2', '#a3acb9', '#8792a2', '#697386', '#4f566b', '#3c4257', '#2a2f45', '#1a1f36'],
  'blue': ["#f5fbff", '#d6ecff', '#a4cdfe', '#7dabf8', '#6c8eef', '#5469d4', '#3d4eac', '#2f3d89', '#212d63', '#131f41'],
  'green': ["#efffed", '#cbf4c9', '#85d996', '#33c27f', '#1ea672', '#09825d', '#0e6245', '#0d4b3b', '#0b3733', '#082429'],
  'yellow': ["#fcf9e9", '#f8e5b9', '#efc078', '#e5993e', '#d97917', '#bb5504', '#983705', '#762b0b', '#571f0d', '#3a1607'],
  'orange': ["#fffaee", '#fee3c0', '#f8b886', '#f5925e', '#e56f4a', '#c44c34', '#9e2f28', '#7e1e23', '#5d161b', '#420e11'],
  'red': ["#fff8f5", '#fde2dd', '#fbb5b2', '#fa8389', '#ed5f74', '#cd3d64', '#a41c4e', '#80143f', '#5e1039', '#420828'],
  'purple': ["#fff8fe", '#fce0f6', '#f0b4e4', '#e28ddc', '#c96ed0', '#a450b5', '#7b3997', '#5b2b80', '#401d6a', '#2d0f55'],
  'pink': ["#ffeef8", '#fedbf0', '#f9b3dd', '#f692ce', '#ec6cb9', '#ea4aaa', '#d03592', '#b93a86', '#99306f', '#6d224f'],
  'cyan': ["#edfdfd", '#c4f1f9', '#7fd3ed', '#4db7e8', '#3a97d4', '#067ab8', '#075996', '#06457a', '#093353', '#042235'],
  'violet': ["#f8f9fe", '#e6e6fc', '#c7c2ea', '#b0a1e1', '#9c82db', '#8260c3', '#61469b', '#4b3480', '#352465', '#1f184e'],
}

const {lineHeights} = typography
const {black, white, pink, gray, blue, green, orange, purple, red, yellow} = colorPrimitives
// General
const colors = {
  bodytext: gray[9],
  black,
  white,
  gray,
  blue,
  green,
  orange,
  purple,
  red,
  yellow,
  pink,
  blackfade15: 'rgba(27, 31, 35, 0.15)',
  blackfade20: 'rgba(27, 31, 35, 0.20)',
  blackfade30: 'rgba(27,31,35,0.3)',
  blackfade35: 'rgba(27, 31, 35, 0.35)',
  blackfade50: 'rgba(27, 31, 35, 0.5)',
  whitefade15: 'rgba(255, 255, 255, 0.15)',
  whitefade50: 'rgba(255, 255, 255, 0.50)',
  whitefade70: 'rgba(255, 255, 255, 0.70)',
  state: {
    error: red[5],
    failure: red[5],
    pending: yellow[7],
    queued: yellow[7],
    success: green[5],
    unknown: gray[4]
  },

  border: {
    blackFade: rgba(black, 0.15),
    blue: blue[5],
    blueLight: blue[2],
    grayLight: lighten(0.03, gray[2]),
    gray: gray[2],
    grayDark: gray[3],
    grayDarker: gray[7],
    green: green[4],
    greenLight: desaturate(0.4, green[3]),
    purple: purple[5],
    red: red[5],
    redLight: desaturate(0.6, red[3]),
    white,
    whiteFade: rgba(white, 0.15),
    yellow: desaturate(0.6, yellow[3])
  },
  counter: {
    bg: 'rgba(27, 31, 35, 0.08)'
  },
  filterList: {
    hoverBg: '#eaecef'
  },
  text: {
    white,
    gray: gray[7],
    grayLight: gray[5],
    grayDark: gray[9],
    red: red[6]
  },
  bg: {
    white,
    gray: gray[1],
    grayLight: gray[0],
    grayDark: gray[9],
    disabled: '#F3F4F6'
  },
  accent: orange[5],
  labels: {
    gray: gray[2],
    grayText: gray[9],
    grayDark: gray[5],
    grayDarkText: gray[9],
    blue: blue[5],
    blueText: blue[5],
    orange: orange[5],
    orangeText: orange[6],
    green: green[5],
    greenText: green[6],
    red: red[6],
    redText: red[6],
    yellow: yellow[6],
    yellowText: yellow[9],
    pink: pink[4],
    pinkText: pink[6],
    purple: purple[4],
    purpleText: [5]
  }
}

const breakpoints = ['544px', '768px', '992px', '1200px']

const fonts = {
  normal: fontStack([
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Helvetica',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji'
  ]),
  mono: fontStack(['SFMono-Regular', 'Consolas', 'Liberation Mono', 'Menlo', 'Courier', 'monospace'])
}

const fontWeights = {
  light: 300,
  normal: 400,
  semibold: 500,
  bold: 600
}

const borderWidths = [0, '1px']

const radii = ['0', '3px', '4px', '100px']

const shadows = {
  small: '0 1px 0 rgba(149, 157, 165, 0.1)',
  medium: '0 3px 6px rgba(149, 157, 165, 0.15)',
  large: '0 8px 24px rgba(149, 157, 165, 0.2)',
  'extra-large': '0 12px 48px rgba(149, 157, 165, 0.3)',
  formControl: 'inset 0px 2px 0px rgba(225, 228, 232, 0.2), rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px',
  formControlDisabled: 'inset 0px 2px 0px rgba(220, 227, 237, 0.3), rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px',
  formControlFocus: 'inset 0px 2px 0px rgba(225, 228, 232, 0.2), rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px',
  primaryShadow: '0px 1px 0px rgba(20, 70, 32, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
  primaryActiveShadow: 'inset 0px 1px 0px rgba(20, 70, 32, 0.2)'
}

const sizes = {
  small: '544px',
  medium: '768px',
  large: '992px',
  xlarge: '1200px'
}

const fontSizes = ['12px', '14px', '16px', '20px', '24px', '32px', '40px', '48px']

const space = ['0', '4px', '8px', '16px', '24px', '32px', '40px', '48px', '64px', '80px', '96px', '112px', '128px']

// Components

const buttons = {
  default: {
    color: {
      default: colors.text.gray,
      hover: colors.text.grayDark,
      focus: colors.text.grayDark,
      active: colors.text.grayDark,
      disabled: gray[4]
    },
    border: {
      default: 'transparent',
      hover: 'transparent',
      focus: 'transparent',
      active: 'transparent',
      disabled: 'transparent'
    },
    bg: {
      default: colors.bg.white,
      hover: colors.bg.white,
      focus: colors.bg.white,
      active: colors.bg.white,
      disabled: colors.bg.grayLight
    },
    shadow: {
      default: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      hover: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 3px 9px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      focus: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      active: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 3px 9px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      disabled: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(79, 86, 107, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
    }
  },
  primary: {
    color: {
      default: white,
      hover: white,
      focus: white,
      active: white,
      disabled: white,
    },
    border: {
      default: 'transparent',
      hover: 'transparent',
      focus: 'transparent',
      active: 'transparent',
      disabled: 'transparent'
    },
    bg: {
      default: blue[5],
      hover: blue[5],
      focus: blue[5],
      active: blue[5],
      disabled: blue[4],
    },
    shadow: {
      default: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(84, 105, 212) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      hover: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(84, 105, 212) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 3px 9px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      focus: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(84, 105, 212) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      active: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(84, 105, 212) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 3px 9px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      disabled: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(108, 142, 239) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
    }
  },
  danger: {
    color: {
      default: white,
      hover: white,
      focus: white,
      active: white,
      disabled: white,
    },
    border: {
      default: 'transparent',
      hover: 'transparent',
      focus: 'transparent',
      active: 'transparent',
      disabled: 'transparent'
    },
    bg: {
      default: red[5],
      hover: red[5],
      focus: red[5],
      active: red[5],
      disabled: red[4],
    },
    shadow: {
      default: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(205, 61, 100) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      hover: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(205, 61, 100) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 3px 9px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      focus: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(205, 61, 100) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      active: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(205, 61, 100) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 3px 9px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
      disabled: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(237, 95, 116) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
    }
  },
  outline: {
    color: {
      default: blue[5],
      hover: white,
      focus: white,
      active: white,
      disabled: gray[4],
    },
    border: {
      default: gray[2], //border-gray
      hover: 'rgba(27, 31, 35, 0.15)',
      focus: 'rgba(27, 31, 35, 0.15)',
      active: 'rgba(27, 31, 35, 0.15)',
      disabled: gray[2],
    },
    bg: {
      default: gray[0],
      hover: blue[5],
      focus: blue[5],
      active: '#035fc7', // 2% darker than hover bg
      disabled: '#F3F4F6',
    },
    shadow: {
      default: '0px 1px 0px rgba(149, 157, 165, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.25)',
      hover: '0px 1px 0px rgba(27, 31, 35, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
      focus: '0 0 0 3px rgba(3, 102, 214, 0.3)',
      active: '0px 1px 0px rgba(27, 31, 35, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
      disabled: '0px 1px 0px rgba(149, 157, 165, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.25)',
    }
  }
}

const flash = {
  default: {
    backgroundColor: blue[1],
    borderColor: 'rgba(4, 66, 137, 0.2)'
  },
  success: {
    backgroundColor: green[1],
    borderColor: 'rgba(23, 111, 44, 0.2)'
  },
  danger: {
    backgroundColor: '#FFE3E6',
    borderColor: 'rgba(158, 28, 35, 0.2)'
  },
  warning: {
    backgroundColor: yellow[1],
    borderColor: 'rgba(176, 136, 0, 0.2)'
  }
}

// this has to be separated from the flash object since we have to use an svg selector to style the icon color
const flashIcon = {
  default: 'rgba(4, 66, 137, 0.6)',
  success: 'rgba(23, 111, 44, 0.8)',
  danger: 'rgba(158, 28, 35, 0.6)',
  warning: yellow[8]
}

const popovers = {
  colors: {
    caret: 'rgba(27, 31, 35, 0.15)'
  }
}

const pagination = {
  borderRadius: radii[2],
  spaceBetween: space[1],
  colors: {
    normal: {
      fg: colors.gray[9]
    },
    disabled: {
      fg: colors.gray[3],
      border: 'transparent'
    },
    hover: {
      border: colors.border.grayLight
    },
    selected: {
      fg: colors.white,
      bg: colors.blue[5],
      border: 'transparent'
    },
    active: {
      border: colors.border.grayLight
    },
    nextPrevious: {
      fg: colors.blue[5]
    }
  }
}

const stateLabels = {
  sizes: {
    small: {
      padding: `${space[1]} ${space[2]}`,
      fontSize: fontSizes[0]
    },
    normal: {
      padding: `${space[2]} 12px`,
      fontSize: fontSizes[1]
    }
  },

  status: {
    issueClosed: {
      backgroundColor: red[5]
    },
    pullClosed: {
      backgroundColor: red[5]
    },
    pullMerged: {
      backgroundColor: purple[5]
    },
    issueOpened: {
      backgroundColor: '#159739' // custom green
    },
    pullOpened: {
      backgroundColor: '#159739' // custom green
    },
    draft: {
      backgroundColor: gray[5]
    }
  }
}

const customTheme = {
  //buttons: {
  //  default: {
  //    color: {
  //      default: 'pink',
  //      disabled: 'yellow',
  //    },
  //  },
  //}

  // General
  borderWidths,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  shadows,
  sizes,
  space,

  // Components
  buttons,
  pagination,
  popovers,
  flash,
  flashIcon,
  stateLabels,
}

function fontStack(fonts) {
  return fonts.map(font => (font.includes(' ') ? `"${font}"` : font)).join(', ')
}

const newTheme = deepmerge(theme, customTheme, {
  // overwrite arrays instead of concatenating
  arrayMerge: (_dest, source) => source
})

export default newTheme
