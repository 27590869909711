import React from 'react'
import { logError } from '../../libs/errorLib'
import {Box} from '@primer/components'

export default class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo)
  }

  render() {
    return this.state.hasError ? (
      <Box pt={100} sx={{textAlign: 'center'}}>
        <h3>Sorry there was a problem loading this page</h3>
      </Box>
    ) : (
      this.props.children
    )
  }
}
