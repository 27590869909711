import React from 'react'
import {Box} from '@primer/components'
import { onError } from '../libs/errorLib'
import { Inline } from '@zendeskgarden/react-loaders'

const Header = ({userId}) => {
  return (
    <Box
      height={[150, null, null, 200]}
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          backgroundColor: 'white',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
          backgroundSize: 'cover',
          ':after': {
            content: '""',
            position: 'absolute',
            opacity: .8,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(rgba(31, 31, 31, 0), #3c4257)',
          }
        }}
      >
      </Box>
      <Box
        maxWidth={[1170, null, null, 830]}
        sx={{
          position: 'relative',
          margin: '0 auto',
          padding: '0 15px',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            paddingTop: 30,
            marginBottom: [35, null, null, 70],
          }}
        >
        </Box>
        <Box
          sx={{
            position: 'relative',
            height: [60, null, null, 80],
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: [60, null, null, 80],
              height: [60, null, null, 80],
              mr: 15,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'radial-gradient(#c1c9d2, #8792a2)',
              borderRadius: '50%',
              overflow: 'hidden',
              fontSize: [40, null, null, 50],
              flex: '0 0 auto',
              boxShadow: '0 0 0 2px #ebebeb',
            }}
          >
            🏂
          </Box>
          <Box
            sx={{
              position: 'relative',
              fontSize: ['20px', null, '25px', '30px'],
              fontWeight: 300,
              filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.4))',
              color: 'white',
              //color: 'rgba(255,255,255,0.7)',
            }}
          >
            {userId}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

function redirectToCheckout({name, description, amount, currency, metadata}) {
  async function handleErrors(response) {
    if (!response.ok) {
      let json
      try {
        json = await response.json()
      } catch(e) {
        const error = new Error(response.statusText)
        error.response = response
        throw error
      }
      const error = new Error(json.error)
      error.response = response
      error.json = json
      throw error
    }
    return response
  }

  return fetch('https://api.cards2cards.com/v2/payment-pages', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      key: 'pk_live_e9zPbV6LDfmdYUBQSjs3WURwsFbLSeCkJ9dekfejShEqzVmRPN5GEtDM',
      items: [{
        name,
        description,
        amount,
        currency,
      }],
      metadata,
    }),
  })
    .then(handleErrors)
    .then(res => res.json())
    .then(res => window.location = res.data.url)
}

const Content = ({userId}) => {
  const nameRef = React.createRef()
  const messageRef = React.createRef()
  const amountRef = React.createRef()
  const currencyRef = React.createRef()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleSubmit = React.useCallback(async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    const name = nameRef.current.value
    const message = messageRef.current.value
    const amount = `${(parseFloat(amountRef.current.value) * 100).toFixed()}`
    const currency = currencyRef.current.value
    console.log('submit', {name, message, amount, currency})
    try {
      await redirectToCheckout({
        name: `Donate`,
        description: `${userId}`,
        amount,
        currency,
        metadata: {
          userId,
          name,
          message,
        },
      })
    } catch (e) {
      onError(e)
      setIsSubmitting(false)
    }
  }, [])

  return (
    <Box>
      <Box
        pt={30}
        px={15}
        pb={30}
        maxWidth={[1170, null, null, 830]}
        sx={{
          margin: '0 auto',
        }}
      >
        <Box as="form" onSubmit={handleSubmit}>
          <Box
            sx={{
              borderRadius: 10,
              boxShadow: '0 10px 38px -10px hsl(208deg 24% 7% / 35%), 0 10px 20px -15px hsl(208deg 24% 7% / 20%)',
              backgroundColor: 'white',
              p: 30,
              mb: 15,
            }}
          >
            <Box>
              <Box ref={nameRef} as="input" sx={{
                width: '100%',
                background: '#fff',
                border: '1px solid #e2e2e2',
                color: '#353535',
                padding: '0 15px 0 15px',
                borderRadius: 10,
                height: 50,
              }} type="text" placeholder="Your name"/>
            </Box>
            <Box mt={20}>
              <Box ref={messageRef} as="textarea" sx={{
                width: '100%',
                background: '#fff',
                border: '1px solid #e2e2e2',
                color: '#353535',
                padding: '15px 15px 60px 15px',
                borderRadius: 10,
              }} placeholder="Your message" />
            </Box>
            <Box mt={20} display={[null, null, 'flex']}>
              <Box sx={{
                width: '100%',
                mb: [20, null, 0],
              }}>
                <Box ref={amountRef} as="input" sx={{
                  width: '100%',
                  background: '#fff',
                  border: '1px solid #e2e2e2',
                  color: '#353535',
                  padding: '0 15px 0 15px',
                  borderRadius: 10,
                  height: 50,
                }} defaultValue={50} placeholder="Amount" type="text"/>
                <Box sx={{
                  pt: 15,
                  fontSize: 12,
                  color: '#838383',
                  lineHeight: '15px',
                }}>
                  Minimum amount: 50 RUB
                </Box>
              </Box>
              <Box ref={currencyRef} as="select" sx={{
                ml: [null, null, 30],
                background: '#fff',
                border: '1px solid #e2e2e2',
                color: '#353535',
                padding: '0 15px 0 15px',
                borderRadius: 10,
                height: 50,
              }}>
                <option value="RUB">RUB</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              borderRadius: 10,
              boxShadow: '0 10px 38px -10px hsl(208deg 24% 7% / 35%), 0 10px 20px -15px hsl(208deg 24% 7% / 20%)',
              backgroundColor: 'white',
              p: 30,
            }}
          >
            <Box as="button" type="submit" sx={{
              height: 50,
              px: 30,
              background: 'linear-gradient(to right, #fb792a 0%, #fb9e2a 100%)',
              textTransform: 'uppercase',
              color: 'white',
              borderRadius: 25,
              fontWeight: 700,
              fontSize: 13,
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              {isSubmitting && <Box
                as="span"
                mr="1"
              >
                <Inline
                  size={24}
                  role="status"
                  aria-hidden="true"
                />
              </Box>}
              Donate
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const Donate = (props) => {
  return (
    <Box
      height="100%"
      sx={{
        position: 'relative',
      }}
    >
      <Header userId={props.userId} />
      <Content userId={props.userId} />
    </Box>
  )
}

export default Donate
